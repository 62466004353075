/** INFO:
* Die Reiehnfolge der Imports darf nicht verändert werden
*/

@forward './styles/material';
@forward './styles/global';
@use 'material-icons/iconfont/material-icons.css'; // INFO: darf nicht in Layer gewrappt werden, da es sonst nicht funktioniert

/* TODO: Pfade bereit stellen für die Styles */

@use '@adnova/jf-ng-components/config' as *;


@layer bootstrap, material, base, generic, component, app;

@layer bootstrap {
  @import "bootstrap/scss/bootstrap";
}
