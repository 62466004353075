@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  mat-nav-list.legal-panel a.active {
    color: mat.get-color-from-palette($primary-palette, 700) !important;
  }

  mat-nav-list.legal-panel a:hover {
    background: mat.get-color-from-palette($accent-palette, 500) !important;
    color: mat.get-contrast-color-from-palette($primary-palette, 700) !important;
  }
}
