/* You can add global styles to this file, and also import other style files */

@use '@adnova/jf-ng-components/config' as *;
@use '@adnova/jf-ng-components/lib';

@font-face {
  font-family: OpenSans;
  src: url(../assets/font/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(../assets/font/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(../assets/font/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/font/OpenSans-BoldItalic.ttf) format('truetype');
}

@layer material, base;

@layer material {
  * {
    font-family: OpenSans;
  }
}

/** INFO:
* Hier kein Layer setzen, da einige Styles sonst nicht funktionieren.
*/

html {
  height: 100%;
  max-height: -webkit-fill-available;
}

body {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

@media(min-width: 992px) {
  body {
    max-height: 100vh;
    min-height: -webkit-fill-available;
    box-sizing: border-box;
  }
}


h3 {
  font-family: OpenSans !important;
  margin: 0px 0px lib.responsive-pixels(6) !important; // INFO: beispiel für verwendung relativer Pixelwerte
}

.ngx-file-drop__drop-zone--over {
  background-color: transparent !important;
}

button[mat-menu-item] bo-icon {
  margin-right: $space-size-m;
  vertical-align: middle;
}

/* Bootstrap Amendments */

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .flex-direction-lg-row {
    flex-direction: row !important;
  }
  .flex-direction-lg-column {
    flex-direction: column !important;
  }
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #fff;
}

$tableAlternateBackgroundEven: #FAFAFA;
$tableAlternateBackgroundOdd: #F2F2F2;

.upload-alternate-even {
  background: $tableAlternateBackgroundEven;
}

.upload-alternate-odd {
  background: $tableAlternateBackgroundOdd;
}

#toolbarViewerMiddle pdf-search-input-field input.toolbarField {
  height: calc(100% - $space-size-m) !important;
}

.mat-toolbar {
  width: 100%;
  height: lib.responsive-pixels(85);
}

.toolbar-title {
  margin-left: 1rem;
}

.flex-auto {
  flex: auto;
}

.cursor-default {
  cursor: default;
}

snack-bar-container.error {
  background: red;
  color: white;
  font-size: larger;
  white-space: pre-wrap;
}

.mat-raised-button {
  font-size: $font-size-x-small;
}

.mat-nav-list .mat-list-item:focus,
.mat-nav-list .mat-list-item:hover {
  background: #e5e5e5 !important;
  border-radius:  lib.responsive-pixels(5);
}

.matMenuBorder {
  border: lib.responsive-pixels(1) solid #e17c0f !important;
}

.mat-menu-item-font {
  font-size: $font-size-x-small;
}

.mat-menu-item-font:enabled {
  color: #414141;
}

.mat-form-field {

  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }

  width: 100%;
  height: lib.responsive-pixels(50);
  font-size: $font-size-x-small;
  color: #414141;

  @media screen and (min-width: 992px) {
    width: lib.responsive-pixels(460);
  }

  &-infix {
    border-top: 0.74375em solid transparent !important;
    height: lib.responsive-pixels(49);
  }

  &-outline-thick > div {
    color: grey !important;
  }

  input,
  mat-select {
    line-height: lib.responsive-pixels(17) !important;
  }
}

label {
  overflow: visible !important;
}

.mat-expansion-panel-content {
  font: 400 lib.responsive-pixels(13) / lib.responsive-pixels(20) "Open Sans", "Helvetica Neue", sans-serif !important;
}

button {
  &.mat-raised-button:enabled {
    &:hover,
    &:focus {
      background-color: var(--accent-hover) !important;
    }
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }

  &.mat-button,
  &.mat-stroked-button,
  &.mat-raised-button {

    border: lib.responsive-pixels(1) solid transparent;
    padding: 0;

    &.icon-button {
      min-width: lib.responsive-pixels(40);
      box-sizing: content-box;

      span.mat-button-wrapper {
        padding: 0;
      }
    }

    span.mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-x-small;
      line-height: lib.responsive-pixels(24);
      height: lib.responsive-pixels(38);
      padding: 0 lib.responsive-pixels(16);

      bo-icon {
        width: lib.responsive-pixels(22);
        height: lib.responsive-pixels(22);
      }

      > *:nth-child(2) {
        margin-left: $space-size-xs;
      }
    }
  }
}

mat-form-field.ng-invalid {
  .mat-form-field-outline-thick > div {
    border-color: red !important;
  }

  .mat-input-element,
  .mat-form-field-label {
    caret-color: red !important;
    color: red !important;
  }
}

.mat-radio-button {
  font-size: $font-size-x-small;
  color: #414141;

  &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #1A1A1A;
  }

  &.mat-accent .mat-radio-inner-circle {
    background-color: #1A1A1A;
  }
}

mat-checkbox {
  font-size: $font-size-x-small;

  .mat-checkbox-frame {
    border: none;
    box-shadow: 0 0 0 lib.responsive-pixels(1) #cbcbcb;
  }
}

input.mat-input-element {
  color: #000;
}

input.mat-input-element:disabled {
  color: #9E9E9E;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .ng-star-inserted {
  color: #000;
  background: #fff;
  padding: 0 lib.responsive-pixels(3);
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.ng-star-inserted mat-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.ng-star-inserted .ng-star-inserted {
  padding: 0;
}

label .ng-star-inserted {
  display: inline-block;
}

button {
  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button {
    box-shadow: none !important;
  }

  &.mat-button:enabled,
  &.mat-raised-button:enabled,
  &.mat-flat-button:enabled {
    &:hover,
    &:focus {
      background-color: var(--default-hover) !important;

      &[color="accent"] {
        background-color: var(--accent-hover) !important;
      }

      &[color="primary"] {
        background-color: var(--primary-hover) !important;
      }
    }
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}
