@use '@angular/material' as mat;
@use 'src/styles/styles-theme' as styles;
@use 'src/app/modules/legal/legal-panel/legal-panel.component-theme' as legalpanel;
@use 'src/app/modules/common/preview/pdf-preview/pdf-preview.component-theme' as pdfpreview;
@use 'src/app/modules/overview/legal-links/legal-links.component-theme' as legallinks;
@use 'src/app/modules/common/preview/download-preview/download-preview.component-theme' as downloadpreview;
@use 'src/app/modules/edit/actions/actions.component-theme' as editActions;
@use 'src/app/modules/widget/to-be-edited-list/to-be-edit-list.component-theme' as toBeEditedList;
@use 'src/app/modules/splice/list-to-splice/list-to-splice.component-theme' as listToSplice;
@use 'src/app/modules/splice/list-available/list-available.component-theme' as listAvailable;

@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

@layer bootstrap, material, base;

@layer material {
  @include mat.all-legacy-component-typographies();
  @include mat.legacy-core();
}


$mat-green: (
  50: #e7f6e8,
  100: #c6e8c7,
  200: #a2d9a3,
  300: #7bcb7e,
  400: #5dc061,
  500: #3db444,
  600: #34a53b,
  700: #279330,
  800: #1a8225,
  900: #006310,
  A100: #c6e8c7,
  A200: #a2d9a3,
  A400: #5dc061,
  A700: #279330,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-orange: (
  50: #fcf2e1,
  100: #f8deb3,
  200: #f4ca82,
  300: #f0b451,
  400: #eda42f,
  500: #eb9616,
  600: #e78b13,
  700: #e17c0f,
  800: #db6d0b,
  900: #d05608,
  A100: #f8deb3,
  A200: #f4ca82,
  A400: #eda42f,
  A700: #e17c0f,
  hover: #b4710d,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-red: (
  50: #ffebef,
  100: #ffcdd3,
  200: #ff9999,
  300: #f87172,
  400: #ff4f4e,
  500: #ff3e31,
  600: #f83432,
  700: #e5282c,
  800: #d82025,
  900: #c81118,
  A100: #ffcdd3,
  A200: #ff9999,
  A400: #ff4f4e,
  A700: #e5282c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$bo-theme-primary: mat.define-palette($mat-green, 700, 300, 900);
$bo-theme-accent: mat.define-palette($mat-orange, 600, 100, 700);
$bo-theme-warn: mat.define-palette($mat-red, 200);

$bo-theme: mat.define-light-theme((
  color: (
    primary: $bo-theme-primary,
    accent: $bo-theme-accent,
    warn: $bo-theme-warn
  )
));

@mixin setVariables($theme) {

  $primary-palette: map-get($theme, 'primary');
  $accent-palette: map-get($theme, 'accent');
  $warn-palette: map-get($theme, 'warn');

  :root {
    --default-hover: #FFF4E2;

    --primary: #{mat.get-color-from-palette($primary-palette, 700)};
    --primary-contrast: #{mat.get-contrast-color-from-palette($primary-palette, 700)};
    --primary-hover: #{mat.get-color-from-palette($primary-palette, 900)};

    --accent: #{mat.get-color-from-palette($accent-palette, 700)};
    --accent-contrast: #{mat.get-contrast-color-from-palette($accent-palette, 700)};
    --accent-hover: #{mat.get-color-from-palette($accent-palette, hover)};

    --warn:  #{mat.get-color-from-palette($warn-palette, 700)};
    --warn-contrast: #{mat.get-contrast-color-from-palette($warn-palette, 700)};
  }
}

// INFO: Sowohl das Theme für Legacy- als auch neue Komponenten setzen. Bis das Legacy Theme von uns ersetzt wurde.

@layer material {
  @include mat.all-legacy-component-themes($bo-theme);
  @include mat.all-component-themes($bo-theme);

  @include styles.color($bo-theme);
  @include legalpanel.color($bo-theme);
  @include pdfpreview.color($bo-theme);
  @include legallinks.color($bo-theme);
  @include downloadpreview.color($bo-theme);
  @include editActions.color($bo-theme);
  @include toBeEditedList.color($bo-theme);
  @include listToSplice.color($bo-theme);
  @include listAvailable.color($bo-theme);

  @include setVariables($bo-theme);
}

mat-icon svg:not([fill]) {
  fill: unset;
}

.mat-dialog-title {
  font-size: 20px;
}

