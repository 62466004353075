@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  #openWholeListBtn:hover:enabled {
    background-color: mat.get-color-from-palette($primary-palette, 700) !important;
    opacity: 0.8;
  }
}

