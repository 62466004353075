@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-stroked-button:not(.mat-button-disabled) {
    border: 1px solid mat.get-color-from-palette($accent-palette, 500) !important;
  }

  .table-settings-menu .mat-stroked-button {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;

    .mat-button-wrapper {
      color: rgb(65, 65, 65);
    }
  }
}
