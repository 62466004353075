@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .multiToolbar-header.pdf-preview-component{
    background: -prefix-linear-gradient(right top, mat.get-color-from-palette($primary-palette, 500), mat.get-color-from-palette($primary-palette, 900));
    background: linear-gradient(to top right, mat.get-color-from-palette($primary-palette, 500), mat.get-color-from-palette($primary-palette, 900));
  }
}
