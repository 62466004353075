@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  bo-list-to-splice .list-border {
    border-color: mat.get-color-from-palette($primary-palette, 700) !important;
    color: mat.get-contrast-color-from-palette($primary-palette, 600) !important;
    background: mat.get-color-from-palette($primary-palette, 700) !important;

    .body {
      color: mat.get-contrast-color-from-palette($primary-palette, 200) !important;

      .empty-hint .hint-header {
        color: mat.get-color-from-palette($primary-palette, 700) !important;
      }
    }
  }
}
